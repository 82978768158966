import React from "react";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: {
        boxShadow: "none"
    }
}));

function Topbar({ className, ...rest }) {
    const classes = useStyles();
    const session = useSelector(state => state.session);

    return (
        <AppBar
            {...rest}
            className={clsx(classes.root, className)}
            color="primary"
        >
            {session.loggedIn && <Redirect to="/" />}
            <Toolbar>
                <Typography component="h2" variant="h3" color="inherit">
                    TOBAKU APPS
                </Typography>
            </Toolbar>
        </AppBar>
    );
}

Topbar.propTypes = {
    className: PropTypes.string
};

export default Topbar;
